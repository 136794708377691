import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import ImageLoader from "./ImageLoader";
import './LevelSelector.scss';
import iconAptNumbers from '../images/interface/icon--apt-numbers.png';
import { debounce, throttle } from "lodash";

type Props<T> = {
    selected:T,
    onSelection:(value:T)=>void,
    options:{
        label:string,
        value:T,
        num?:number
    }[],
}


export default function<T>({selected, options, onSelection}:Props<T>){
    const topRef:MutableRefObject<HTMLDivElement> = useRef() as MutableRefObject<HTMLDivElement>;
    
    const [state, setState] = useState({
        mouseDown:false,
    })

    useEffect(() => {
        function handleMouseUp() {
            console.log('mouse up');
            setState((prevState) => ({
                ...prevState,
                mouseDown: false,
            }));
        }

        // Add mouseup event listeners

        // document.addEventListener("mouseup", handleMouseUp);

        // Cleanup event listeners on component unmount
        return () => {

            document.removeEventListener("mouseup", handleMouseUp);
        };
    }, []); // Empty dependency array to run once on mount/unmount


    function handleMove(y:number){
        /*
        if(isDown===false || state.isDown===false){
            setState({
                ...state,
                isDown:isDown!
            })
        }else{
            const rect = refA.current!.getBoundingClientRect();
            const relX = x - rect.x;
            var percent = relX / rect.width;
            if(percent < 0) percent = 0;
            if(percent > 1) percent = 1;
            setState({
                ...state,
                percent,
                isDown,
            });
        }
        */

        var rect = topRef.current.getBoundingClientRect();
        var top = rect.top;
        var bottom = rect.bottom;
        
        y -= top;
        var percent = y / (bottom-top);
        if(percent < 0) percent = 0;
        if(percent > 1) percent = 1;

        var index = Math.floor(options.length*percent);
        var option = options[index];
        if(option) if(option.value != selected) onItemClicked(option)();
    }

    function onItemClicked(option:Props<T>['options'][number]){
        return function(){
            setTimeout(() => onSelection(option.value), 100);
        }
    }

    function onMouseDown(){
        setState({
            ...state,
            mouseDown:true,
        })
    }

    function onMouseUp(){
        setState({
            ...state,
            mouseDown:false,
        })
    }

    const debouncedOnMouseOver = throttle((option) => {
        if (state.mouseDown) onItemClicked(option)();
    }, 1075);

    const animationFrameRef = useRef<number | null>(0);

    const handleMouseMove = (option: any) => {
          animationFrameRef.current = requestAnimationFrame(() => {
            if (state.mouseDown) onItemClicked(option)();
            // Your logic here
            animationFrameRef.current = null; // Reset for the next frame
          });
        
      };

      function handleMouseUp() {
        if (animationFrameRef.current) {
          cancelAnimationFrame(animationFrameRef.current);
          animationFrameRef.current = null;
        }
        setState((prevState) => ({ ...prevState, mouseDown: false }));
      }

      //onTouchMove={e => handleMove(e.touches[0].pageY)} 
    return (
        <div className="LevelSelector" ref={topRef} onTouchMove={e => handleMove(e.touches[0].pageY)}   onTouchStart={onMouseDown} onTouchEnd={handleMouseUp} onMouseDown={onMouseDown} onMouseUp={handleMouseUp}>
            <div className="LevelSelectorLine">
                <svg height="900px" width="200">
                    <line x1="130" y1="10" x2="130" y2="77%" stroke="black" strokeWidth="1" />
                </svg>
            </div>
        {

            options.map((option, index) => (
                <div key={option.label}  onMouseOver={e => handleMouseMove(option)} className="LevelSelectorItem" onClick={onItemClicked(option)} onMouseDown={onItemClicked(option)}  onMouseUp={handleMouseUp}>
                    <div className="LevelSelectorLabel">{option.label.toUpperCase()}</div>
                    <div className="LevelSelectorIcon">
                        <svg height="40" width="40">
                            <circle cx="20" cy="20" r="16" stroke="#7a614b" strokeWidth="1" fill="#fbe8d4" />
                            { option.value===selected && 
                                <circle cx="20" cy="20" r="10" fill="#7a614b" />
                            }
                        </svg>
                    </div>
                    

                    { (option.num!=undefined && option.num!=0) && <div className="LevelSelectorNumber" style={{ margin: '0px', position: 'absolute', top: 130 + (index * 41.8) + 'px', left: '240px'}}>
                        <ImageLoader src={iconAptNumbers} />
                        <div><span className='TearDropLabel'>{option.num}</span></div> 
                    </div> }
                </div>
            ))    
        }
        </div>
    )
}
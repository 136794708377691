import React, { useEffect, useState } from 'react';
import { Context } from '../../store/store';
import { selectEnlarged } from '../../../shared/enlarged';
import { connect } from 'react-redux';

const PhotoHotspot = ({ popup, width, url, href, top, left, selectEnlarged }: {popup: { url: string, position: string }, width: string, url: string, href: string, top: string, left: string, selectEnlarged: (data: any) => {} }) => {
    const [mouseOver, setMouseOver] = useState<boolean>(false)
    const [styleObject, setStyleObject] = useState({
        position: 'absolute' as const,
        marginTop: '-50px',
        width: '200px' as const,
        height: 'auto' as const,
        marginLeft: '20px',
    })
    
    useEffect(() => {
        if (popup.position === 'left') {
            console.log('left');
            setStyleObject({
                position: 'absolute' as const,
                marginTop: '-50px',
                width: '200px' as const,
                height: 'auto' as const,
                marginLeft: '-280px',
            })
        } else if (popup.position === 'right') {
            console.log('right');
            setStyleObject({
                position: 'absolute' as const,
                marginTop: '-50px',
                width: '200px' as const,
                height: 'auto' as const,
                marginLeft: '20px',
            })
        }
    }, [])

    return (
        <button style={{ border: 'none', zIndex: 10, position: 'absolute', top: top, left: left, padding: 0, margin: 0, height: '190px'}} onClick={() => {       selectEnlarged( { srcList: [href] } ) }} >
            <img src={url} style={{ width: width, height: 'auto', padding: 0}}  onMouseEnter={() => setMouseOver(true)} onMouseOut={() => setMouseOver(false)}/>
            { (mouseOver && popup) && <img src={popup.url} style={styleObject}/>}
        </button>
        
    )
}
const mapDispatchToProps = (dispatch: any) => ({
    selectEnlarged: (data: any) => dispatch(selectEnlarged(data)),
  });

const mapStateToProps = () => {

}
  export default connect(mapStateToProps, mapDispatchToProps)(PhotoHotspot);

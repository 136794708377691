import React from "react";
import './SaleIndicator.scss';
type Props = {
    sold:boolean,
    res:boolean,
    title:string,
}


export default function ({sold, res, title}:Props){
    if(!sold && !res) return <div className="SaleIndicatorJustText">{title}</div>;

    return (
        <div className="SaleIndicator" data-res={res} data-sold={sold}>
            <div>{ sold ? "SOLD" : res ? "RES" : ""}</div>
        </div>
    )
}

import React, { useRef, useState } from 'react';
import { NavLink, useRouteMatch, useLocation } from 'react-router-dom';
import { gsap } from 'gsap/all';


export default function ButtonHeaderSubMenu ( { title, options } ) {
    const matchArray = ['/location-lifestyle', '/location-map', '/location-aerial', '/interiors-images', '/interiors-finishes', '/interiors-detail', '/gallery-building', '/gallery-amenities', '/gallery-flythrough', '/finishes','/living', '/gallery-interiors'];
    const match = useRouteMatch(matchArray);
    var selected = false;

    const containerRef = useRef(null);
    const btnRef = useRef(null);
    const subMenuRef = useRef(null);
    let isMenuOpen = false;

    const optionsList = [];
    let option;
    let lineBreake = 10;
    // Not yet in use
    // let lineGallery;

    for (let i = 0; i < options.length; i++) { 
        
        option = options[i];

        if (option?.path === match?.path) {
            selected = true;
        }
        optionsList.push(
            <li key={i}>
                <NavLink 
                className={`header--nav__link-in-sub-menu ${lineBreake}`} activeClassName='header--nav__link-selected' 
                to={option.path}> { option.title }</NavLink>
            </li>
        );
        
    }

    const openSubMenu = async () => {
        if (!isMenuOpen) {
            isMenuOpen = true;
            subMenuRef.current.style.display = 'block';
            subMenuRef.current.style.top = '75%';
            subMenuRef.current.style.opacity = 0;
            gsap.to(subMenuRef.current, 0.3, { top: '80%', opacity: 1 } );
            document.addEventListener('click', closeSubMenu);
            containerRef.current.addEventListener('mouseleave', onMouseLeaveContainer);
        }
    }

    const onMouseLeaveContainer = (event) => {
        closeSubMenu();
    }

    const closeSubMenu = (event) => {
        if (isMenuOpen) {
            isMenuOpen = false;
            containerRef.current.removeEventListener('mouseleave', onMouseLeaveContainer);
            document.removeEventListener('click', closeSubMenu);
            gsap.killTweensOf(subMenuRef.current);
            subMenuRef.current.style.display = 'none';
        }
    }

    return (
        <>
            <div ref={ containerRef }>
                <button className={ selected ? 'header--nav__link-selected' :'header--nav__link' } ref={ btnRef } onMouseEnter={ () => setTimeout(openSubMenu, 0) } onClick={ openSubMenu }>{ title }</button>
                <div className='header--nav__sub-menu' ref={ subMenuRef }>
                    <ul>
                        { optionsList }
                    </ul>
                </div> 
            </div> 
        </>       
    );

}
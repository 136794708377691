//export const youtube = [{title: 'CAR PARK VIDEO', type: 'video', left: '1474px', top: '720px', src: 'https://www.youtube.com/watch?v=SQrOHlheHHM', href: 'https://www.youtube.com/watch?v=SQrOHlheHHM'}];

//LOCATION
export const locationlifestyle1 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/location-lifestyle/bkg--location-lifestyle-1.jpg'},
]
export const locationlifestyle2 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/location-lifestyle/bkg--location-lifestyle-2.jpg' },
]

export const locationMap = [
	{ title: '1', left: '0px', top: '0px', src: '/img/location-map/bkg--location-map.jpg' },
]

export const aerial = [
	{ title: '1', left: '0px', top: '0px', src: '/img/location-aerial/bkg--aerial.jpg' },

]

//INTERIORS LIVING

export const living1 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-living/bkg--interiors-living-01.jpg' }
];

export const living2 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-living/bkg--interiors-living-02.jpg' }
];

export const living3 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-living/bkg--interiors-living-03.jpg' }
];

export const living4 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-living/bkg--interiors-living-04.jpg' }
];

export const living5 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-living/bkg--interiors-living-05.jpg' }
];

export const living6 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-living/bkg--interiors-living-06.jpg' }
];

export const living7 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-living/bkg--interiors-living-07.jpg' }
];

export const living8 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-living/bkg--interiors-living-08.jpg' }
];

export const living9 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-living/bkg--interiors-living-09.jpg' }
];

export const living10 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-living/bkg--interiors-living-10.jpg' }
];

export const living11 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-living/bkg--interiors-living-11.jpg' }
];

export const living12 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-living/bkg--interiors-living-12.jpg' }
];

export const living13 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-living/bkg--interiors-living-13.jpg' }
];

export const living14 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-living/bkg--interiors-living-14.jpg' }
];

export const living15 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-living/bkg--interiors-living-15.jpg' }
];

export const living16 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-living/bkg--interiors-living-16.jpg' }
];

export const living17 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-living/bkg--interiors-living-17.jpg' }
];

export const living18 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-living/bkg--interiors-living-18.jpg' }
];

export const finishes = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-finishes/bkg--interiors-finishes-1.jpg' }
];

//INTERIORS (Not used)
export const interiorsImages1 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-living/bkg--interiors-images-01.jpg'},
]
export const interiorsImages2 = [
	{ title: '2', left: '0px', top: '0px', src: '/img/interiors-living/bkg--interiors-images-02.jpg'},
]

export const interiorsImages3 = [
	{ title: '2', left: '0px', top: '0px', src: '/img/interiors-living/bkg--interiors-images-03.jpg'},
]

export const interiorsImages4 = [
	{ title: '2', left: '0px', top: '0px', src: '/img/interiors-living/bkg--interiors-images-04.jpg'},
]

export const interiorsImages5 = [
	{ title: '2', left: '0px', top: '0px', src: '/img/interiors-living/bkg--interiors-images-05.jpg'},
]

export const interiorsImages6 = [
	{ title: '2', left: '0px', top: '0px', src: '/img/interiors-living/bkg--interiors-images-06.jpg'},
]

export const interiorsImages7 = [
	{ title: '2', left: '0px', top: '0px', src: '/img/interiors-living/bkg--interiors-images-07.jpg'},
]

export const interiorsImages8 = [
	{ title: '2', left: '0px', top: '0px', src: '/img/interiors-living/bkg--interiors-images-08.jpg'},
]

export const interiorsImages9 = [
	{ title: '2', left: '0px', top: '0px', src: '/img/interiors-living/bkg--interiors-images-09.jpg'},
]


export const interiorsFinishes1 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-finishes/bkg--interiors-finishes-1.jpg'},
]

export const interiorsFinishes2 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-finishes/bkg--interiors-finishes-2.jpg'},
]

export const interiorsFinishes3 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-finishes/bkg--interiors-finishes-3.jpg'},
]

export const interiorsFinishes4 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-finishes/bkg--interiors-finishes-4.jpg'},
]

export const interiorsFinishes5 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-finishes/bkg--interiors-finishes-5.jpg'},
]

export const interiorsDetail1 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-1.jpg'},

]

export const interiorsDetail2 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-2.jpg'},
]

export const interiorsDetail3 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-3.jpg'},
]

export const interiorsDetail4 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-4.jpg'},
]

export const interiorsDetail5 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-5.jpg'},
]

export const interiorsDetail6 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-6.jpg'},
]

export const interiorsDetail7 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-7.jpg'},
]

export const interiorsDetail8 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-8.jpg'},
]

export const interiorsDetail9 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-9.jpg'},
]

export const interiorsDetail10 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-10.jpg'},
]

export const interiorsDetail11 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-11.jpg'},
]


export const interiorsDetail12 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-12.jpg'},
]

export const interiorsDetail13 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-13.jpg'},
]

export const interiorsDetail14 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-14.jpg'},
]

export const interiorsDetail15 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-15.jpg'},
]

export const interiorsDetail16 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/detail-16.jpg'},
]

export const interiorsDetail17 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/detail-17.jpg'},
]

export const interiorsDetail18 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/detail-18.jpg'},
]

export const interiorsDetail19 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/detail-19.jpg'},
]

export const interiorsDetail20 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/detail-20.jpg'},
]

export const interiorsDetail21 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/detail-21.jpg'},
]

export const interiorsDetail22 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/detail-22.jpg'},
]

export const interiorsDetail23 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/detail-23.jpg'},
]

export const interiorsDetail24 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/detail-24.jpg'},
]
export const interiorsDetail25 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/detail-25.jpg'},
]

export const interiorsDetail26 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/detail-26.jpg'},
]

export const interiorsDetail27 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/detail-27.jpg'},
]

export const interiorsDetail28 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/detail-28.jpg'},
]

export const interiorsDetail29 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/detail-29.jpg'},
]

export const interiorsDetail30 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/detail-30.jpg'},
]

export const interiorsDetail31 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/detail-31.jpg'},
]

export const interiorsDetail32 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/detail-32.jpg'},
]

//TEAMS
export const team1 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/team/bkg--team-1.jpg'},
]
export const team2 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/team/bkg--team-2.jpg'},
]


export const elevations1 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/elevations/bkg--elevations-1.jpg'},
]

export const elevations2 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/elevations/bkg--elevations-2.jpg'},
]

export const elevations3 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/elevations/bkg--elevations-3.jpg'},
]

export const elevations4 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/elevations/bkg--elevations-4.jpg'},
]

export const elevations5 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/elevations/bkg--elevations-5.jpg'},
]

//DESIGN
export const design1 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/design/bkg--design-1.jpg' },
]
export const design2 = [
	{ title: '2', left: '0px', top: '0px', src: '/img/design/bkg--design-2.jpg' },
]
export const design3 = [
	{ title: '2', left: '0px', top: '0px', src: '/img/design/bkg--design-3.jpg' },
]
export const design4 = [
	{ title: '2', left: '0px', top: '0px', src: '/img/design/bkg--design-4.jpg' },
]
export const design5 = [
	{ title: '2', left: '0px', top: '0px', src: '/img/design/bkg--design-5.jpg' },
]
export const design6 = [
	{ title: '2', left: '0px', top: '0px', src: '/img/design/bkg--design-6.jpg' },
]
export const design7 = [
	{ title: '2', left: '0px', top: '0px', src: '/img/design/bkg--design-7.jpg' },
]
export const design8 = [
	{ title: '2', left: '0px', top: '0px', src: '/img/design/bkg--design-8.jpg' },
]
export const design9 = [
	{ title: '2', left: '0px', top: '0px', src: '/img/design/bkg--design-9.jpg' },
]
export const design10 = [
	{ title: '2', left: '0px', top: '0px', src: '/img/design/bkg--design-10.jpg' },
]
export const design11 = [
	{ title: '2', left: '0px', top: '0px', src: '/img/design/bkg--design-11.jpg' },
]
export const design12 = [
	{ title: '2', left: '0px', top: '0px', src: '/img/design/bkg--design-12.jpg' },
]
export const design13 = [
	{ title: '2', left: '0px', top: '0px', src: '/img/design/bkg--design-13.jpg' },
]
export const design14 = [
	{ title: '2', left: '0px', top: '0px', src: '/img/design/bkg--design-14.jpg' },
]
export const design15 = [
	{ title: '2', left: '0px', top: '0px', src: '/img/design/bkg--design-15.jpg' },
]

//GALLERY
export const building = [
	{ title: '1', left: '68px', top: '17px', src: '/img/gallery-building/gallery-tmb-building-01.png', href: '/img/gallery-building/gallery-building-01.png' },
	{ title: '2', left: '518px', top: '17px', src: '/img/gallery-building/gallery-tmb-building-02.png', href: '/img/gallery-building/gallery-building-02.png' },
	{ title: '3', left: '967px', top: '17px', src: '/img/gallery-building/gallery-tmb-building-03.png', href: '/img/gallery-building/gallery-building-03.png' },
	{ title: '4', left: '1416px', top: '17px', src: '/img/gallery-building/gallery-tmb-building-04.png', href: '/img/gallery-building/gallery-building-04.png' },
	{ title: '5', left: '68px', top: '338px', src: '/img/gallery-building/gallery-tmb-building-05.png', href: '/img/gallery-building/gallery-building-05.png' },
	{ title: '6', left: '518px', top: '338px', src: '/img/gallery-building/gallery-tmb-building-06.png', href: '/img/gallery-building/gallery-building-06.png' },
	{ title: '7', left: '967px', top: '338px', src: '/img/gallery-building/gallery-tmb-building-07.png', href: '/img/gallery-building/gallery-building-07.png' },
	{ title: '8', left: '1416px', top: '338px', src: '/img/gallery-building/gallery-tmb-building-08.png', href: '/img/gallery-building/gallery-building-08.png' },
	/*{ title: '9', left: '68px', top: '660px', src: '/img/gallery-building/gallery-tmb-building-09.png', href: '/img/gallery-building/gallery-building-09.png' },
	{ title: '10', left: '518px', top: '660px', src: '/img/gallery-building/gallery-tmb-building-10.png', href: '/img/gallery-building/gallery-building-10.png' },
	{ title: '11', left: '967px', top: '660px', src: '/img/gallery-building/gallery-tmb-building-11.png', href: '/img/gallery-building/gallery-building-11.png' },
	{ title: '12', left: '1416px', top: '660px', src: '/img/gallery-building/gallery-tmb-building-12.png', href: '/img/gallery-building/gallery-building-12.png' },*/
];

export const building2 = [
	{ title: '1', left: '68px', top: '17px', src: '/img/gallery-building/gallery-building-13-tmb.png', href: '/img/gallery-building/gallery-building-13.png' },
{ title: '2', left: '518px', top: '17px', src: '/img/gallery-building/gallery-building-14-tmb.png', href: '/img/gallery-building/gallery-building-14.png' },
{ title: '3', left: '967px', top: '17px', src: '/img/gallery-building/gallery-building-15-tmb.png', href: '/img/gallery-building/gallery-building-15.png' },
//{ title: '4', left: '1416px', top: '17px', src: '/img/gallery-building/gallery-building-16-tmb.png', href: '/img/gallery-building/gallery-building-16.png' },
];
export const amenities = [
	{ title: '1', left: '68px', top: '17px', src: '/img/gallery-amenities/gallery-tmb-amenities-01.png', href: '/img/gallery-amenities/gallery-amenities-01.png' },
	{ title: '2', left: '518px', top: '17px', src: '/img/gallery-amenities/gallery-tmb-amenities-02.png', href: '/img/gallery-amenities/gallery-amenities-02.png' },
	{ title: '3', left: '967px', top: '17px', src: '/img/gallery-amenities/gallery-tmb-amenities-03.png', href: '/img/gallery-amenities/gallery-amenities-03.png' },
	{ title: '4', left: '1416px', top: '17px', src: '/img/gallery-amenities/gallery-tmb-amenities-04.png', href: '/img/gallery-amenities/gallery-amenities-04.png' },
	{ title: '5', left: '68px', top: '338px', src: '/img/gallery-amenities/gallery-tmb-amenities-05.png', href: '/img/gallery-amenities/gallery-amenities-05.png' },
	{ title: '6', left: '518px', top: '338px', src: '/img/gallery-amenities/gallery-tmb-amenities-06.png', href: '/img/gallery-amenities/gallery-amenities-06.png' },
	//{ title: '7', left: '967px', top: '338px', src: '/img/gallery-amenities/gallery-tmb-amenities-07.png', href: '/img/gallery-amenities/gallery-amenities-07.png' },
	//{ title: '8', left: '1416px', top: '338px', src: '/img/gallery-amenities/gallery-tmb-amenities-08.png', href: '/img/gallery-amenities/gallery-amenities-08.png' },
]

export const interiors = [
	{ title: '1', left: '68px', top: '17px', src: '/img/gallery-interiors/gallery-tmb-interiors-01.png', href: '/img/gallery-interiors/gallery-interiors-01.png' },
	{ title: '2', left: '518px', top: '17px', src: '/img/gallery-interiors/gallery-tmb-interiors-02.png', href: '/img/gallery-interiors/gallery-interiors-02.png' },
	{ title: '3', left: '967px', top: '17px', src: '/img/gallery-interiors/gallery-tmb-interiors-03.png', href: '/img/gallery-interiors/gallery-interiors-03.png' },
	{ title: '4', left: '1416px', top: '17px', src: '/img/gallery-interiors/gallery-tmb-interiors-04.png', href: '/img/gallery-interiors/gallery-interiors-04.png' },
	{ title: '5', left: '68px', top: '338px', src: '/img/gallery-interiors/gallery-tmb-interiors-05.png', href: '/img/gallery-interiors/gallery-interiors-05.png' },
	{ title: '6', left: '518px', top: '338px', src: '/img/gallery-interiors/gallery-tmb-interiors-06.png', href: '/img/gallery-interiors/gallery-interiors-06.png' },
	{ title: '7', left: '967px', top: '338px', src: '/img/gallery-interiors/gallery-tmb-interiors-07.png', href: '/img/gallery-interiors/gallery-interiors-07.png' },
	{ title: '8', left: '1416px', top: '338px', src: '/img/gallery-interiors/gallery-tmb-interiors-08.png', href: '/img/gallery-interiors/gallery-interiors-08.png' },
	{ title: '9', left: '68px', top: '660px', src: '/img/gallery-interiors/gallery-tmb-interiors-09.png', href: '/img/gallery-interiors/gallery-interiors-09.png' },
	{ title: '10', left: '518px', top: '660px', src: '/img/gallery-interiors/gallery-tmb-interiors-10.png', href: '/img/gallery-interiors/gallery-interiors-10.png' },
	{ title: '11', left: '967px', top: '660px', src: '/img/gallery-interiors/gallery-tmb-interiors-11.png', href: '/img/gallery-interiors/gallery-interiors-11.png' },
	{ title: '12', left: '1416px', top: '660px', src: '/img/gallery-interiors/gallery-tmb-interiors-12.png', href: '/img/gallery-interiors/gallery-interiors-12.png' },

]

export const interiors2 = [
	{ title: '1', left: '68px', top: '17px', src: '/img/gallery-interiors/gallery-tmb-interiors-13.png', href: '/img/gallery-interiors/gallery-interiors-13.png' },
	{ title: '2', left: '518px', top: '17px', src: '/img/gallery-interiors/gallery-tmb-interiors-14.png', href: '/img/gallery-interiors/gallery-interiors-14.png' },
	{ title: '3', left: '967px', top: '17px', src: '/img/gallery-interiors/gallery-tmb-interiors-15.png', href: '/img/gallery-interiors/gallery-interiors-15.png' },
	{ title: '4', left: '1416px', top: '17px', src: '/img/gallery-interiors/gallery-tmb-interiors-16.png', href: '/img/gallery-interiors/gallery-interiors-16.png' },
	{ title: '5', left: '68px', top: '338px', src: '/img/gallery-interiors/gallery-tmb-interiors-17.png', href: '/img/gallery-interiors/gallery-interiors-17.png' },
	{ title: '6', left: '518px', top: '338px', src: '/img/gallery-interiors/gallery-tmb-interiors-18.png', href: '/img/gallery-interiors/gallery-interiors-18.png' },
	
]
// useEffect not currently use
// import React, { useState,useEffect } from 'react';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import ReactPlayer from 'react-player/vimeo'
import LoadContainer from '../../shared/LoadContainer';
import './stylesMovies.scss';


export default function ViewMovies (props) {
    
    const [ isMovieActive, setIsMovieActive ] = useState(false);

    const [ currMovieSrc, setCurrMovieSrc ] = useState('');

    // Not currently use
    // const [count, setCount] = useState(0);

    
    // useEffect(() => {
        
    //     const path = window.location.pathname;
    //     const pathName = path.split('/').pop();

    //     if(count ==0 && pathName==='queen-wharf-residences'){
    //         setCount(1);
    //         playMovie('https://player.vimeo.com/video/450949511');
    //     }

    // }, [count]);
    useEffect(() => {
        playMovie('https://vimeo.com/1020785540/e0cd343939') 
    }, []);
    

    const playMovie = (src) => {
        const newSrc = src;
        setCurrMovieSrc(newSrc);
        setIsMovieActive(true);
    }


    return (
        <LoadContainer>
            <img src='./img/movie/bkg--movie.jpg' width='1920' height='1080' alt='movies bkg' />
            <button className='movies--button__ird' onClick={ () => playMovie('https://vimeo.com/1020785540/e0cd343939') }></button>
            <CSSTransition className='movies--enlarged' in={ isMovieActive } timeout={ 300 } classNames='fade' unmountOnExit>
                <div>
                    <ReactPlayer className='movies--enlarged__movie' url={ currMovieSrc } playing={ true } controls={ true }  width='100%' height='100%' />
                    
                    <button className='gallery--enlarged__close-btn'  onClick={() => setIsMovieActive(false)} > CLOSE </button>

                </div>
            </CSSTransition> 
        </LoadContainer>
    );
    




}
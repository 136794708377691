import { SalesData } from "../components/residences/data/sales-data"
// import { popupData } from "../data/popup-data";

export type State = { data: typeof initialState };

const initialState = {
    sales:null as SalesData|null,
    requestSales:false,
    isActive: false,
    url: '',
    isDay: true,
}

const setDay = (day: boolean) => (state: State) => {
    console.log('isDay');
    state.data.isDay = day;
}
const selectEnlarged = (url: string, isActive: boolean) => (state: State) => {
    state.data.isActive = isActive;
    state.data.url = url;
}

const closeEnlarged = () => (state: State) => {
    state.data.isActive = false;
    state.data.url = '';
}


const setSalesData = (data:SalesData) => (state:State) => {
    state.data.sales = data;
    state.data.requestSales = false;
}


const requestSales = () => (state:State) => {
    state.data.requestSales = true;
}


export default {
    initialState,
    actions:{
        setSalesData,
        requestSales,
        selectEnlarged,
        closeEnlarged,
        setDay,
    }
}

import React, { useRef, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import sha256 from 'crypto-js/sha256';
import ViewHeader from '../shared/header/ViewHeader';
import ViewRoutes from '../routes/ViewRoutes';
import ConnectViewEnlarged from '../shared/enlarged/ConnectViewEnlarged';
import styles from './stylesApp.module.scss';
import ConnectViewOverlay from './components/overlay/ConnectViewOverlay';
import './App.css';
import { Provider } from './store/store';
import { Provider as AppRoute } from './components/residences/AppRoute';
import SalesDataLoader from './components/residences/SalesDataLoader';

export default function App () {
  
		let basename = process.env.PUBLIC_URL;
		//"homepage": "https://scharp-dev.com/qwr-staging/app",
		//"homepage": "https://scharp-dev.com/qwr/app",

		//let basename = '/qwr/app';
	
		console.log("BASENAME:", basename);
		const updateDimensions = () => {

			let amountImgOffset;
			let currScaleFactor;
			const windowWidth = window.innerWidth; 
			const windowHeight = window.innerHeight;
			const windowAspectRatio = windowWidth / windowHeight;
			const appAspectRatio = 1920 / 1080;
				
			containerRef.current.style.marginLeft = '0px'; //reset for another positon
			containerRef.current.style.marginTop = '0px'; //reset for another positon
		
			if (windowAspectRatio < appAspectRatio) { 
				currScaleFactor = windowWidth / 1920;
				containerRef.current.style.transform = 'scale(' + currScaleFactor + ')';
				amountImgOffset = (containerRef.current.getBoundingClientRect().height - windowHeight) / 2; //to vertically align
				containerRef.current.style.marginTop = -amountImgOffset + 'px'; 

			} else {
				currScaleFactor = windowHeight / 1080;
				containerRef.current.style.transform = 'scale(' + currScaleFactor + ')';
				amountImgOffset = (containerRef.current.getBoundingClientRect().width - windowWidth) / 2; //to horiztonally align
				containerRef.current.style.marginLeft = -amountImgOffset + 'px';
			
			}

		}

		const containerRef = useRef(null);
		useEffect(() => {
			if (containerRef.current) {
				updateDimensions();
			}
			const handleResize = () => {
				updateDimensions();
			}
			window.addEventListener('resize', handleResize);
			return () => {
				window.removeEventListener('resize', handleResize);
			}
		}, [containerRef]);


		const [ isLoggedIn, setIsLoggedIn ] = useState(false);
		const [ inputPassword, setInputPassword ] = useState('');
		const password = '905041f9238a45ee97b0bb6691bebc812d7618f53b6ee8daed900e4836d021a5'; //sha-256 hash - https://passwordsgenerator.net/sha256-hash-generator/ - enter text as per "password"
		const refStatus = useRef(null);
		const onPasswordChange = (event) => {
			const newPassword = event.target.value;
			setInputPassword(newPassword);
		}
		const onSubmitPassword = (event) => {
			event.preventDefault();
			const hashDigest = sha256(JSON.stringify(inputPassword)).toString();
			if (hashDigest === password) {
				const date = new Date();
				date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
				const expires = "; expires=" + date.toUTCString();
				document.cookie = "isLoggedIn=true" + expires + "; path=/";

				setIsLoggedIn(true);
				refStatus.current.style.display = 'none';
			} else {
				refStatus.current.style.display = 'block';
			}
		}

		const showLoggedIn = () => {
			return (
					<>
							<ViewHeader />
							<ViewRoutes />
							<ConnectViewOverlay />
							<ConnectViewEnlarged />
					</>
			);
		}

		const showLogin = () => {
			return (
				<div className={ styles.loginContainer }>
					<form onSubmit={ onSubmitPassword }>
						<label htmlFor="pwd">Password</label>
						<input type="password" value={ inputPassword } onChange={ onPasswordChange }></input>
						<p ref={ refStatus }>Invalid password</p>
						<button type="submit"  value="Submit">Submit</button>
					</form>
				</div>
			)
		}

		useEffect(() => {
			const cookies = document.cookie.split(';');
			let isLoggedIn = false;
			for (let i = 0; i < cookies.length; i++) {
				const cookie = cookies[i];
				const cookieName = cookie.split('=')[0];
				if (cookieName === 'isLoggedIn') {
					isLoggedIn = true;
					break;
				}
			}
			setIsLoggedIn(isLoggedIn);
		}, []);


		return (
			<Router basename={ basename }>
				<Provider>
					<SalesDataLoader>

					<AppRoute>

					<div className={ styles.container } ref={ containerRef }>
						{ isLoggedIn ? showLoggedIn() : showLogin() }
					</div>
					</AppRoute>
					</SalesDataLoader>

				</Provider>
			</Router>
		);

}

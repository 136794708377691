

type CoordData = {
    title:string,
    x:number,
    y:number,
    max:boolean,
}

function parse(jsonData: any): Record<string, CoordData> {
  const result: Record<string, CoordData> = {};
  
  // Access the items array from the JSON structure
  const items = jsonData.psd.item;

  for (const item of items) {
    const title = item.$.title; // Access the title
    result[title] = {
      title: title.split(" ").join(""),
      x: parseFloat(item.$.xPos), // Access xPos and parse to float
      y: parseFloat(item.$.yPos) + 100, // Access yPos and adjust by 100
      max: false,
    };
  }

  console.log('result', result);
  return result;
}


//const xml = require('./coords/00/level1_00.xml')

//console.log('xml', xml)

const coordData = {
    0: {
      ...parse(require('./coords/00/level4_00.xml')),
      ...parse(require('./coords/00/level5_00.xml')),
      ...parse(require('./coords/00/level6_00.xml')),
      ...parse(require('./coords/00/level7_00.xml')),
      ...parse(require('./coords/00/level8_00.xml')),
      ...parse(require('./coords/00/level9_00.xml')),
      ...parse(require('./coords/00/level10_00.xml')),
      ...parse(require('./coords/00/level11_00.xml')),
      ...parse(require('./coords/00/level12_00.xml')),
      ...parse(require('./coords/00/level13_00.xml')),
      ...parse(require('./coords/00/level14_00.xml')),
      ...parse(require('./coords/00/level15_00.xml')),
      ...parse(require('./coords/00/level16_00.xml')),
    },
    18: {
      ...parse(require('./coords/18/level4_18.xml')),
      ...parse(require('./coords/18/level5_18.xml')),
      ...parse(require('./coords/18/level6_18.xml')),
      ...parse(require('./coords/18/level7_18.xml')),
      ...parse(require('./coords/18/level8_18.xml')),
      ...parse(require('./coords/18/level9_18.xml')),
      ...parse(require('./coords/18/level10_18.xml')),
      ...parse(require('./coords/18/level11_18.xml')),
      ...parse(require('./coords/18/level12_18.xml')),
      ...parse(require('./coords/18/level13_18.xml')),
      ...parse(require('./coords/18/level14_18.xml')),
      ...parse(require('./coords/18/level15_18.xml')),
      ...parse(require('./coords/18/level16_18.xml')),

      
    }
  } as Record<number, Record<string, CoordData>>;

  console.log('cooooo(', coordData);
export default coordData;
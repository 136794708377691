export const menuItemData = [
  {
    menuItemLabel: 'North',
    type: 'standalone',
    afternoon: process.env.PUBLIC_URL + '/vr-app/afternoon_N/index.htm',
    dusk: process.env.PUBLIC_URL + '/vr-app/evening_N/index.htm', //no toggle buttons?
  },
  {
    menuItemLabel: 'East',
    type: 'standalone',
    afternoon: process.env.PUBLIC_URL + '/vr-app/afternoon_E/index.htm',
    dusk: process.env.PUBLIC_URL + '/vr-app/evening_E/index.htm', //no toggle buttons?
  },
  {
    menuItemLabel: 'South',
    type: 'standalone',
    afternoon: process.env.PUBLIC_URL + '/vr-app/afternoon_S/index.htm',
    dusk: process.env.PUBLIC_URL + '/vr-app/evening_S/index.htm', //no toggle buttons?
  },
  {
    menuItemLabel: 'West',
    type: 'standalone',
    afternoon: process.env.PUBLIC_URL + '/vr-app/afternoon_W/index.htm',
    dusk: process.env.PUBLIC_URL + '/vr-app/evening_W/index.htm', //no toggle buttons?
  },
];
export const popupData = {
    0: {
        one: { width: '59px', top: '250px', left: '90px', src: require('../../../components/images/popup/icon--image.png'), popup: { url: require('../../../components/images/popup/residences-image-1-tmb.png'), position: 'right'},  href: require('../../../components/images/popup/residences-image-1.jpg')},
        two: { width: '59px', top: '150px', left: '1290px', src: require('../../../components/images/popup/icon--image.png'), popup: { url: require('../../../components/images/popup/residences-image-2-tmb.png'), position: 'left'}, href: require('../../../components/images/popup/residences-image-2.jpg') },
        three: { width: '59px', top: '750px', left: '1020px', src: require('../../../components/images/popup/icon--image.png'),popup: { url: require('../../../components/images/popup/residences-image-4-tmb.png'), position: 'right'},href: require('../../../components/images/popup/residences-image-4.jpg') },
        four: { width: '59px', top: '750px', left: '180px', src: require('../../../components/images/popup/icon--image.png'), popup: { url: require('../../../components/images/popup/residences-image-3-tmb.png'), position: 'right'},href: require('../../../components/images/popup/residences-image-3.jpg') },
    },
    18: {
        one: { width: '59px', top: '210px', left: '1250px', src: require('../../../components/images/popup/icon--image.png'), popup: { url: require('../../../components/images/popup/residences-image-1-tmb.png'), position: 'right'}, href: require('../../../components/images/popup/residences-image-1.jpg')},
        two: { width: '59px', top: '25px', left: '1150px', src: require('../../../components/images/popup/icon--image.png'),popup: { url: require('../../../components/images/popup/residences-image-3-tmb.png'), position: 'right'},href: require('../../../components/images/popup/residences-image-3.jpg') },
        three: { width: '59px', top: '250px', left: '150px',src: require('../../../components/images/popup/icon--image.png'), popup: { url: require('../../../components/images/popup/residences-image-4-tmb.png'), position: 'right'},href: require('../../../components/images/popup/residences-image-4.jpg') },
        four: { width: '59px', top: '690px', left: '100px', src: require('../../../components/images/popup/icon--image.png'), popup: { url: require('../../../components/images/popup/residences-image-2-tmb.png'), position: 'right'},href: require('../../../components/images/popup/residences-image-2.jpg') },
    }
}
import coordData from "./coord-data";


const plans:any = {
    '0101': [101],
    '0102': [102],
    '0103': [103],
    '0104': [104], 
    '0105': [105],
    '0106': [106],
    '0107': [107],
    '0201_0301_0401_0501_0601_0701_0801_0901_1001_1101_1201': [201, 301, 401, 501, 601, 701, 801, 901, 1001, 1101, 1201],
    '0202_0302_0402_0502_0602_0702_0802_0902_1002_1102_1202': [202, 302, 402, 502, 602, 702, 802, 902, 1002, 1102, 1202],
    '0203_0303_0403_0503_0603_0703_0803_0903_1003_1103_1203': [203, 303, 403, 503, 603, 703, 803, 903, 1003, 1103, 1203],
    '0204_0304_0404_0504_0604_0704_0804_0904_1004_1104_1204': [204, 304, 404, 504, 604, 704, 804, 904, 1004, 1104, 1204],
    '0205_0305_0405_0505_0605_0705_0805_0905_1005_1105_1205': [205, 305, 405, 505, 605, 705, 805, 905, 1005, 1105, 1205],
    '0206_0306_0406_0506_0606_0706_0806_0906_1006_1106_1206': [206, 306, 406, 506, 606, 706, 806, 906, 1006, 1106, 1206],
    '0207_0307_0407_0507_0607_0707_0807_0907_1007_1107_1207': [207, 307, 407, 507, 607, 707, 807, 907, 1007, 1107, 1207],
    //'1301': [1301],
    //'1302': [1302],
    //'1303': [1303],
    //'1304': [1304],
    //'1305': [1305],
    //'1306': [1306],


}


function getPlan(apartmentId:string){
    var planKeys = Object.keys(plans);
    for(var k=0; k < planKeys.length; k++){
        var plan:any = planKeys[k];
        var apartments:number[] = plans[plan];
        var id = parseInt(apartmentId);
        if(apartments.indexOf(id) != -1){
            return plan;
        }
    }
    return null;
}


function getPDF(planId:string|null){
    if(planId == '4444'
    || planId == '4445'
    || planId == '4446'){
        return `SEK25321_Lumia_FloorplanTemplate_Penthouse_V7_FA_LR_${planId}.pdf`
    }else if(planId && planId.indexOf("P")==0){
        return `SEK25321_Lumia_FloorplanTemplate_Standard_V8_FA_LR_${planId}.pdf`
    }else{
        return planId + ".pdf";
    }
}


export default {
    getPlan,
    getPDF
}
import React, { useContext } from 'react';
import './DayNightSelector.scss'
import { Context } from '../../store/store'

const DayNightSelector = ({ isDay = true }: { isDay: boolean }) => {
    const store = useContext(Context);

    const handleDay = () => {
        store.actions.data.setDay(true);
        console.log('handle day');

    }

    const handleNight = () => {
        store.actions.data.setDay(false);
        console.log('handle night');
    }

    return (
        <div className='daynightselector'>
            <label className='custom-radio'>
                <span className='daynightlabel' style={{ marginLeft: '7px'}}>Day</span>
                <input
                    type="radio"
                    name="daynight"
                    checked={isDay}
                    onChange={handleDay}
                />
                <span className="checkmark"></span>
            </label>

            <label className='custom-radio'>
                <span className='daynightlabel' style={{ marginLeft: '2px'}}>Night</span>
                <input
                    type="radio"
                    name="daynight"
                    checked={!isDay}
                    onChange={handleNight}
                />
                <span className="checkmark"></span>
            </label>
        </div>
    );
}

export default DayNightSelector;

import React from 'react';
import ViewHome from './home/ViewHome';
import ViewGalleryLocationLifestyle from './location-lifestyle/ViewGalleryLocationLifestyle';
import ViewMap from './map/ViewMap';

import { locationlifestyle1, locationlifestyle2, amenities, building, team1, team2, design1, design2, design3, design4, design5, design6, design7, design8, design9, design10, design11, design12, design13, design14, design15, interiorsImages1, interiorsImages2, interiorsImages3, interiorsImages4, interiorsImages5, interiorsImages6, interiorsImages7, interiorsImages8, interiorsImages9, interiorsFinishes1, interiorsFinishes2, interiorsFinishes3, interiorsFinishes4, interiorsFinishes5, interiorsDetail1, interiorsDetail2, interiorsDetail3, interiorsDetail4, interiorsDetail5, interiorsDetail6, interiorsDetail7, interiorsDetail8, interiorsDetail9, interiorsDetail10, interiorsDetail11, interiorsDetail12, interiorsDetail13, interiorsDetail14, interiorsDetail15, interiorsDetail16, interiorsDetail17, interiorsDetail18, interiorsDetail19, interiorsDetail20, interiorsDetail21, interiorsDetail22, interiorsDetail23, interiorsDetail24, interiorsDetail25, interiorsDetail26, interiorsDetail27, interiorsDetail28, interiorsDetail29, interiorsDetail30, interiorsDetail31, interiorsDetail32, building2, elevations1, elevations2, elevations4, elevations5, elevations3, locationMap, aerial, living1, living2, living3, living4, living5, living6, living7, living8, living9, living10, interiors, living11, living12, living13, living14, living15, living16, living17, living18, finishes, interiors2 } from './gallery/data/gallery';


import ViewInteriorsImages from './interiors-images/ViewInteriorsImages';
import ViewInteriorsFinishes from './interiors-finishes/ViewInteriorsFinishes';
import ViewInteriorsDetail from './interiors-detail/ViewInteriorsDetail';
import ViewGalleryElevations from './elevations/ViewGalleryElevations'
import ViewGalleryTeam from './team/ViewGalleryTeam';

import ViewGalleryBuilding from './gallery-building/ViewGalleryBuilding';
import ViewGalleryAmenities from './gallery-amenities/ViewGalleryAmenities';
import ViewGalleryFlythrough from './gallery-fly-through/ViewGalleryFlythrough';
import ViewGalleryDesign from './design/ViewGalleryDesign';
import ViewMovies from './movies/ViewMovies';
import ViewImage from '../app/components/viewImage/ViewImage';
import ViewAmenities from './amenities/ViewAmenities';
// import ViewAmenities from './amenities/ViewAmenities';
import { data as dataAmenities } from './amenities/dataAmenities';
import ViewGalleryInteriors from './gallery-interiors/ViewGalleryInteriors';
import floorPlans from './residences/floor-plans';
import ViewVirtualTour from './tour/ViewVirtualTour';
import ViewGallery from './gallery/ViewGallery';
import { dataInteriors } from './color-scheme/dataGallery';
import ConnectViewGallery from './color-scheme/ConnectViewGallery';
// import interiorsColorScheme from './interiors-color-scheme/interiors-color-scheme';
// import ViewResidences from './residences/ViewResidences';
// import { data as dataResidences } from './residences/dataResidences';
// import ViewResidences from './residences/ViewResidences';
export const dataRoutes = [
    {
        title: 'Movie',
        path: '/movie',
        component: ViewMovies,
    },
        {
        title: 'Location',
        routes: [
            {
                title: 'Aerial',
                path: '/location-aerial',
                component: ViewGalleryLocationLifestyle,
                data: [aerial],
            },
            {
                title: 'Lifestyle',
                path: '/location-lifestyle',
                component: ViewGalleryLocationLifestyle,
                data: [locationlifestyle1, locationlifestyle2],
            },
            {

                title: 'Map',
                path: '/location-map',
                component: ViewGalleryTeam,
                data: [locationMap],
            },
        ]
    },
    {
        title: 'Design',
        path: '/design',
        component: ViewGalleryDesign,
        data: [design1, design2, design3, design4 ,design5 ,design6, design7, design8, design9, design10, design11, design12, design13],
    },
    {
        title: 'Interiors',
        routes: [
            {
                title: 'Living',
                path: '/living',
                component: ViewInteriorsImages,
                data: [living1, living2, living3, living4, living5, living6, living7, living8, living9, living10, living11, living12, living13, living14, living15, living16, living17, living18],
            },

            {
                title: 'Finishes',
                path: '/finishes',
                component: ViewGalleryLocationLifestyle,
                data: [finishes],
            },
        ]
    },
    {
        title: 'Residences',
        path: '/residences',
        component: floorPlans,

    },
    {

        title: 'Views',
        path: '/views',
        component: ViewVirtualTour,
    },
    {

        title: 'Team',
        path: '/team',
        component: ViewGalleryTeam,
        data: [team1, team2],
    },
    
    
   
    // {
    //     title: 'Masterplan',
    //     path: '/residences',
    //      routes: [
    //         {
    //             title: 'Residences',
    //             path: '/plans',
    //             component: ViewAmenities,
    //             data: dataAmenities
    //         },
    //         {
    //             title: 'Elevations',
    //             path: '/elevations',
    //             component: ViewGalleryElevations,
    //             data: [elevations1, elevations2, elevations3, elevations4, elevations5],
    //         },
    //     ]
    // },
    {

        title: 'Gallery',
        routes: [
            {
                title: 'Building',
                path: '/gallery-building',
                component: ViewGalleryBuilding,
                data: [building],
            },
            {
                title: 'Amenities',
                path: '/gallery-amenities',
                component: ViewGalleryAmenities,
                data: [amenities],
            },
            {
                title: 'Interiors',
                path: '/gallery-interiors',
                component: ViewGalleryInteriors,
                data: [interiors, interiors2],
            },
            /*
            {
                title: 'Video',
                path: '/gallery-video',
                component: ViewGalleryFlythrough
            }
                */
        ]
    },
    {
        title: 'Home',
        path: '/',
        component: ViewHome
    }

];
import React, { useEffect, useState } from "react";

const MenuStacker2 = ({ selected, setSelected, selectedValueOne, selectedValueTwo, selectedValueThree, selectedValueFour }) => {
    useEffect(() => {
        setSelected(selectedValueOne.data);
    }, []);

    return (
        <div className="menu-stacker2">
                <>
                    <li className={ selected === selectedValueOne.data ? "menu-item-stacker-selected" : "menu-item-stacker" } onClick={() => setSelected(selectedValueOne.data)}>{selectedValueOne.copy}</li>
                    <li className={ selected === selectedValueTwo.data ? "menu-item-stacker-selected" : "menu-item-stacker" } onClick={() => setSelected(selectedValueTwo.data)}>{selectedValueTwo.copy}</li>
                    <li className={ selected === selectedValueThree.data ? "menu-item-stacker-selected" : "menu-item-stacker" } onClick={() => setSelected(selectedValueThree.data)}>{selectedValueThree.copy}</li>
                    <li className={ selected === selectedValueFour.data ? "menu-item-stacker-selected" : "menu-item-stacker" } onClick={() => setSelected(selectedValueFour.data)}>{selectedValueFour.copy}</li>
                </>
           
        </div>

        
    );
}


export default MenuStacker2;
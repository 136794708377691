import React, { useEffect, useState } from "react";
import MenuItem from "./MenuItem";
import { menuItemData } from './data';
import './menu.scss';
import MenuStacker from "./MenuStacker";
import MenuStacker2 from "./MenuStacker2";

const MenuContainer = ({ onUrlChange }) => {
    const [streetSelected, setStreetSelected] = useState(0);
    const [duskSunsetSelected, setDuskSunsetSelected] = useState('sunset');

    useEffect(() => {
        const levelsData = menuItemData[streetSelected][duskSunsetSelected];
        onUrlChange(levelsData);
    }, [duskSunsetSelected, streetSelected]);

    //const morningItem = {
    //    menuItemLabel: 'Morning'
    //}

    //const eveningItem = {
    //    menuItemLabel: 'Afternoon'
   // }

    return (
        <>
        <ul className="menu-group">

            <MenuStacker 
            selected={duskSunsetSelected} 
            setSelected={setDuskSunsetSelected} 
            selectedValueOne={{ data: "afternoon", copy: "AFTERNOON" }} 
            selectedValueTwo={{ data: "dusk", copy: "DUSK"}} 
            />

            <MenuStacker2 
            selected={streetSelected} 
            setSelected={setStreetSelected} 
            selectedValueOne={{ data: 0, copy: "NORTH"}} 
            selectedValueTwo={{ data: 1, copy: "EAST"}} 
            selectedValueThree={{ data: 2, copy: "SOUTH"}} 
            selectedValueFour={{ data: 3, copy: "WEST"}} 
            />

        </ul>
        </>
    )
}

export default MenuContainer;